import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useArticleSelector } from "../../redux/selector/article";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { getAllArticle } from "../../redux/slices/article";
import { updateStatus, userDetail } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import DeactivateUserModal from "../Modal/DeactivateUserModal";
import ChangeUserPlan from "../Modal/UserChangePlan";
import DeleteUserModal from "../Modal/deleteUserModal";
import moment from "moment";

const UserDetail = () => {
  document.title = "Subscribers";
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const articleSelector = useArticleSelector();
  const userSelector = UseDashBoardSelector();
  const { loading } = articleSelector;
  const navigate = useNavigate();
  const [userDetails, setUserDeatil] = useState("");
  console.log(userDetails, "userDetails");

  const [articleList, setArticleList] = useState([]);
  const [userBlockStatus, setUserBlockStatus] = useState("");
  const [key, setKey] = useState(Math.random());
  const [status, setStatus] = useState("");
  const [expandedIds, setExpandedIds] = useState({});
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  /**
   * to convert date string format
   * @param {*} dateStr
   * @returns
   */
  function convertDate(dateStr) {
    const [month, day, year] = dateStr.split("/");
    const paddedDay = day?.padStart(2, "0");
    const paddedMonth = month?.padStart(2, "0");
    return `${paddedMonth}/${paddedDay}/${year}`;
  }

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  /**
   *
   * @param {*} e
   * @param {*} id
   */
  const handleToggleExpand = (e, id) => {
    setExpandedIds((prevExpandedIds) => ({
      ...prevExpandedIds,
      [id]: !prevExpandedIds[id],
    }));
  };

  /**
   * get user details
   */
  const getOneUserDetail = () => {
    let params = {
      id: id,
    };
    dispatch(
      userDetail({
        ...params,
        cb(res) {
          if (res?.data) {
            setUserDeatil(res?.data?.data);
            setUserBlockStatus(res?.data?.data?.userInfo?.status);
          }
        },
      })
    );
  };

  /**
   * to change status
   * @param {*} status
   * @param {*} flag
   */

  const handleChangeStatus = (status, flag) => {
    if (status === "Active") {
      setStatus(status);
      setModalDetail({
        show: true,
        flag: flag,
        type: flag,
      });
      setKey(Math.random());
    } else {
      let params = {
        _id: id,
        status:
          status === "Inactive"
            ? "Active"
            : status == "Blocked"
            ? "Blocked"
            : "",
      };
      dispatch(
        updateStatus({
          ...params,
          cb(res) {
            if (res?.data) {
              getOneUserDetail();
            }
          },
        })
      );
    }
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   * to unblock
   * @param {*} status
   */

  const handleChangeStatusUnBlocked = (status) => {
    let params = {
      _id: id,
      status: status === "Active" ? "Active" : "",
    };
    dispatch(
      updateStatus({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneUserDetail();
          } else {
          }
        },
      })
    );
  };

  /**
   * to get article list
   */
  const getAllArticleList = () => {
    let params = {
      page: 1,
      limit: 5,
      userId: id,
    };
    dispatch(
      getAllArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            setArticleList(res?.data?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneUserDetail();
    getAllArticleList();
  }, []);

  return (
    <>
      <div className="content-wrap User_Details">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <Link to="/users"> */}
                <div className="userDeatilHead d-inline-flex align-items-center ">
                  <img
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid table_UserName pointCursor"
                    onClick={() => navigate(-1)}
                  />
                  <h1 className="mainHead32">User Details</h1>
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          {userSelector?.loading ? (
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          ) : (
            <>
              <div className="userDetailInfo userDetailMain">
                <div className="userDetailLeft">
                  <img
                    src={
                      userDetails?.userInfo?.profilePhoto || images.dummyProfile
                    }
                    alt="arrowMainimg"
                    className="img-fluid profileMainImg"
                  />
                  <div className="userDeatilInner">
                    <h2 className="userNameText text-capitalize">
                      {`${userDetails?.userInfo?.firstName || ""} ${
                        userDetails?.userInfo?.lastName || ""
                      }`}
                    </h2>
                    <h6 className="userLabelText mt-3 ">
                      Email:{" "}
                      <span className="labelBold">
                        {userDetails?.userInfo?.email}
                      </span>
                    </h6>
                    <h6 className="userLabelText mt-2">
                      Phone Number:{" "}
                      <span className="labelBold">
                        +{userDetails?.userInfo?.dialCode}{" "}
                        {userDetails?.userInfo?.phoneNo}
                      </span>
                    </h6>
                    <h6 className="userLabelText mt-2">
                      Created On:{" "}
                      <span className="labelBold">
                        {moment(userDetails?.userInfo?.createdAt).format(
                          "MMM-DD-YYYY"
                        )}
                      </span>
                    </h6>
                    <h6 className="userLabelText mt-2">
                      Location:{" "}
                      <span className="labelBold">
                        {userDetails?.userInfo?.location}
                      </span>
                    </h6>
                    <h6 className="userLabelText mt-2">
                      Business Name:{" "}
                      <span className="labelBold">
                        {userDetails?.userInfo?.businessName}
                      </span>
                    </h6>
                    <h6 className="userLabelText mt-2">
                      Active Clients:{" "}
                      <span className="labelBold">
                        {/* {userDetails?.userInfo?.noOfActiveClients} */}
                        {userDetails?.userInfo?.noOfActiveClients
                          ? userDetails?.userInfo?.noOfActiveClients > 5000
                            ? "5000 +"
                            : userDetails?.userInfo?.noOfActiveClients
                          : " "}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="userDetailOuterRyt">
                  <div className="userDetailRight">
                    {/* {userBlockStatus === "Active" ? (
                      <button
                        className="blockbtn userblockbtn"
                        onClick={() => handleChangeStatus("Blocked")}
                        disabled
                      >
                        Block
                      </button>
                    ) : (
                      ""
                    )} */}

                    {userBlockStatus === "Blocked" ? (
                      <button
                        className="blockbtn"
                        onClick={() => handleChangeStatusUnBlocked("Active")}
                      >
                        UnBlock
                      </button>
                    ) : (
                      <button
                        className="deactivateBtn"
                        onClick={() =>
                          handleChangeStatus(userBlockStatus, "DeactivateUser")
                        }
                      >
                        {userBlockStatus === "Active"
                          ? "Deactivate"
                          : "Activate"}
                      </button>
                    )}
                    <button
                      className="deactivateBtn"
                      onClick={() => {
                        handleOpenModal("changeUserPlan");
                      }}
                    >
                      Change User Plan
                    </button>
                    <button
                      className="userDeleteBtn"
                      onClick={() => {
                        handleOpenModal("deleteUser");
                      }}
                    >
                      <img
                        src={images.deleteLight}
                        alt="deleteBin"
                        className="img-fluid"
                      />
                    </button>
                  </div>
                  {userDetails?.recentSubscription ? (
                    <>
                      <div className="d-flex align-items-center flex-column gap-3">
                        <div className="userPlanOuter">
                          <div className="userPlanLeft">
                            <h4 className="planText">Subscription plan</h4>
                            <h4 className="priceText mt-3">
                              {" "}
                              ${userDetails?.recentSubscription?.price}
                              <span className="monthText">
                                /{userDetails?.recentSubscription?.planInterval}
                              </span>
                            </h4>
                            {userDetails?.recentSubscription?.planInterval ===
                            "Free" ? (
                              ""
                            ) : userDetails?.nextPayment === false ? (
                              <>
                                <h6 className="userLightPara">
                                  Expires:{" "}
                                  <span className="form_subheading">
                                    {convertDate(
                                      userDetails?.recentSubscription
                                        ?.expiryDate
                                    )}
                                  </span>
                                </h6>
                                {userDetails?.recentSubscription
                                  ?.purchasedBy === "self" && (
                                  <h6 className="userLightPara">
                                    Plan Unsubscribed
                                  </h6>
                                )}
                              </>
                            ) : (
                              <h6 className="userLightPara">
                                Next Payment:{" "}
                                <span className="form_subheading">
                                  {convertDate(
                                    userDetails?.recentSubscription?.expiryDate
                                  )}
                                </span>
                              </h6>
                            )}
                            {/* {userDetails?.upcomingSubscription && (
                              <h6 className="userLightPara mt-3">
                                Upcomming plan- $
                                {userDetails?.upcomingSubscription?.price}/
                                {
                                  userDetails?.upcomingSubscription
                                    ?.planInterval
                                }
                              </h6>
                            )} */}

                            {/* {userDetails?.upcomingSubscription


                            } */}
                          </div>
                          <div className="UserPlanRight">
                            <h4 className="includeText mb-2">INCLUDES:</h4>
                            <div className="flexPlan mt-2">
                              <img
                                src={images.checkMark}
                                alt="checkMark image"
                                className="img-fluid"
                              />
                              <h6 className="planDesc">
                                Maximum Articles{" "}
                                {userDetails?.recentSubscription
                                  ?.planInterval !== "Free"
                                  ? `per ${userDetails?.recentSubscription?.planInterval}`
                                  : ""}{" "}
                                :{" "}
                                {userDetails?.recentSubscription
                                  ?.maxArticlesPerMonth || "----"}
                              </h6>
                            </div>
                            {userDetails?.recentSubscription?.planInterval !==
                              "Free" && (
                              <div className="flexPlan mt-2">
                                <img
                                  src={images.checkMark}
                                  alt="checkMark image"
                                  className="img-fluid"
                                />
                                <h6 className="planDesc">
                                  Markup :{" "}
                                  {userDetails?.recentSubscription?.markUp ||
                                    "----"}
                                  %
                                </h6>
                              </div>
                            )}
                            {userDetails?.recentSubscription?.features?.map(
                              (value, idx) => (
                                <div className="flexPlan mt-2" key={idx}>
                                  <img
                                    src={images.checkMark}
                                    alt="checkMark image"
                                    className="img-fluid"
                                  />
                                  <h6 className="planDesc">{value || ""}</h6>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="userPlanOuter noPlanOuter">
                        <h4 className="userDetail_Noplan"> No Active Plan </h4>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="recentUserSec">
                <div className="flexDiv my-4">
                  <h4 className="recentText m-0">Articles</h4>
                  {articleList?.length > 0 ? (
                    <button
                      type="button"
                      className="seeBtn"
                      onClick={() => navigate(`/article/${id}`)}
                    >
                      See All
                      <img
                        src={images.rightArrow}
                        className="img-fluid"
                        alt="rightArrowImg"
                      />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="table-responsive recentTable articleTable ">
                  <table class="table table-dark m-0">
                    <thead>
                      <tr className="recentRow">
                        <th scope="col" className="recentHead">
                          SR. NO.
                        </th>
                        <th scope="col" className="recentHead">
                          Genre
                        </th>
                        <th scope="col" className="recentHead">
                          Title
                        </th>
                        <th scope="col" className="recentHead">
                          Description
                        </th>
                        <th scope="col" className="recentHead">
                          Posted By
                        </th>
                        <th scope="col" className="recentHead">
                          Status
                        </th>
                        <th scope="col" className="recentHead text-start">
                          ACTION
                        </th>
                      </tr>
                    </thead>

                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={9}>
                            <div className="loaderOuter d-flex justify-content-center text-light">
                              <div
                                className="spinner-grow text-light"
                                role="status"
                              ></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <>
                        <tbody>
                          {articleList?.length > 0 ? (
                            articleList?.map((data, idx) => {
                              const isExpanded = expandedIds[data?._id];
                              // Create a temporary element to parse the HTML content
                              const tempElement = document.createElement("div");
                              tempElement.innerHTML = data?.description;

                              // Extract text data by removing HTML tags
                              const extractedText =
                                tempElement.textContent ||
                                tempElement.innerText;
                              return (
                                <tr>
                                  <td
                                    className="recentSubHead subcribers_Users"
                                    onClick={() =>
                                      navigate(
                                        `/article-details/${data?._id}`,
                                        {
                                          state: {
                                            flag: "userDetails",
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <h4 className="tableSubText">
                                      {data?.articleId}
                                    </h4>
                                  </td>
                                  <td className="recentSubHead">
                                    <h4 className="tableSubText">
                                      {data?.categoryName || "---"}
                                    </h4>
                                  </td>
                                  <td
                                    className="recentSubHead table_UserName"
                                    onClick={() =>
                                      navigate(
                                        `/article-details/${data?._id}`,
                                        {
                                          state: {
                                            flag: "userDetails",
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <h4 className="tableSubText">
                                      {isExpanded || data?.title?.length <= 25
                                        ? data?.title
                                        : `${data?.title?.slice(0, 25)}...`}
                                    </h4>
                                  </td>
                                  <td className="recentSubHead">
                                    <h4 className="tableSubText DescHead">
                                      <span className="DescTicket">
                                        {isExpanded ||
                                        extractedText?.length <= 85
                                          ? extractedText
                                          : `${extractedText?.slice(0, 85)}...`}
                                      </span>

                                      {/* {extractedText?.length > 75 ? (
                                        <span
                                          to="#"
                                          className="readBtn"
                                          onClick={(e) =>
                                            handleToggleExpand(e, data?._id)
                                          }
                                        >
                                          {isExpanded
                                            ? " Read Less"
                                            : "Read More"}
                                        </span>
                                      ) : null} */}
                                      {/* <span className="DescTicket">
                                  {(data?.description?.length > 20 && (data?._id != selectedId)) ? `${data?.description?.slice(0, 20)}...` : data?.description}
                                </span>
                                {(data?.description?.length > 20 && (data?._id != selectedId)) ? <span to="#" className="readBtn" onClick={() => setSelectedId(data?._id)}>Read More</span> : ""} */}
                                    </h4>
                                  </td>
                                  <td className="recentSubHead">
                                    <div className="nameProfile">
                                      <div className="profile-outer">
                                        <img
                                          src={
                                            data?.Users?.profilePhoto
                                              ? data?.Users?.profilePhoto
                                              : images.dummyProfile
                                          }
                                          className="img-fluid profileImg_"
                                          alt="profileImg"
                                        />
                                      </div>
                                      <h4 className="tableSubText">
                                        {`${
                                          data?.Users?.firstName
                                            ? data?.Users?.firstName
                                            : ""
                                        } ${
                                          data?.Users?.lastName
                                            ? data?.Users?.lastName
                                            : ""
                                        }`}
                                      </h4>
                                    </div>
                                  </td>
                                  <td className="recentSubHead ">
                                    <h4 className="tableSubText">
                                      <button
                                        type="button"
                                        title={
                                          data?.submittedForPublication &&
                                          !data?.published
                                            ? "Submitted for Publication"
                                            : ""
                                        }
                                        className={
                                          data?.status == "under_review"
                                            ? "pendingTkt"
                                            : data?.status == "rejected"
                                            ? "rejectedTkt"
                                            : "completeTkt"
                                        }
                                      >
                                        {data?.status == "under_review"
                                          ? "Pending"
                                          : data?.submittedForPublication &&
                                            !data?.published
                                          ? "Submitted for Publication"
                                          : data?.submittedForPublication &&
                                            data?.published
                                          ? "Published"
                                          : data?.status}
                                      </button>
                                    </h4>
                                  </td>
                                  <td className="recentSubHead text-center">
                                    {/* <Link
                                      to={{
                                        pathname: `/article-details/${data?._id}`,
                                        state: {
                                          flag: "userDetails",
                                          // id:
                                        },
                                      }}
                                    > */}
                                    <button
                                      className="editBtn"
                                      type="button"
                                      onClick={() =>
                                        navigate(
                                          `/article-details/${data?._id}`,
                                          {
                                            state: {
                                              flag: "userDetails",
                                            },
                                          }
                                        )
                                      }
                                    >
                                      <img
                                        src={images.eyeImg}
                                        className="img-fluid"
                                        alt="editPen image"
                                      />
                                      View
                                    </button>
                                    {/* </Link> */}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7} className="noFoundHead">
                                <h3 className="text-center m-0 subhead24">
                                  No Articles Found!
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "DeactivateUser"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "DeactivateUser"
            ? "DeactivateUser"
            : modalDetail.flag === "deleteUser"
            ? "DeactivateUser"
            : modalDetail.flag === "changeUserPlan"
            ? "changeUserPlanModal"
            : ""
        }
        child={
          modalDetail.flag === "DeactivateUser" ? (
            <DeactivateUserModal
              close={() => handleOnCloseModal()}
              deactivateId={id}
              status={status}
              getOneUserDetail={() => getOneUserDetail()}
            />
          ) : modalDetail.flag === "deleteUser" ? (
            <DeleteUserModal close={() => handleOnCloseModal()} userId={id} />
          ) : modalDetail.flag === "changeUserPlan" ? (
            <ChangeUserPlan
              close={() => handleOnCloseModal()}
              userId={id}
              getOneUserDetail={getOneUserDetail}
              userDetails={userDetails}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "DeactivateUser" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to De-Activate?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteUser" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete User?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "changeUserPlan" ? (
            <>
              <h2 className="modal_Heading text-capitalize">
                Change user plan
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default UserDetail;
